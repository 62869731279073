import axios from "axios";
import { message } from "antd";

axios.interceptors.response.use(null, error => {
    // If is an unexpected error, not 400s
    if (error.response && (error.response.status < 400 || error.response.status >= 500))
        message.error("An unexpected error occurred");

    return Promise.reject(error);
})

export function setAuthorization(token) {
  if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export function deleteAuthorization(){
  delete axios.defaults.headers.common["Authorization"];
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
    setAuthorization,
    deleteAuthorization
}
