import http from "./httpService";

const endPoint = "/api/v1/Images";

export function addImage(Content, MediaType, Name, SectionFlag) {
    return http.post(`${endPoint}`, { Content, MediaType, Name, SectionFlag });
}

export function getImage(id) {
    return http.get(`${endPoint}/`, {
        params: {
            id: id
        }
    });
}

export function deleteImage(id) {
    return http.delete(`${endPoint}/${id}`);
}

export function thumbnail(id) {
    return http.get(`${endPoint}/Thumbnail`, {
        params: {
            id: id
        }
    });
}

export function sections(section) {
    return http.get(`${endPoint}/Sections`, {
        params: {
            section: section
        }
    });
}

export default { addImage, getImage, deleteImage, thumbnail, sections };