import http from "./httpService";

const endPoint = "/api/v1/ImagesCarousel";

export interface CreateImageCarousel {
    image : string,
    mediaType : string,
    name : string
}

export function create(newImage : CreateImageCarousel){
    return http.post(endPoint, newImage);
}

export function getImages() {
    return http.get(endPoint);
}

export function deleteImage(id:any) {
    return http.delete(`${endPoint}/${id}`);
}

export function updateImagePosition(id:any, position:number) {
    return http.put(`${endPoint}/${id}?new=${position}`);
}

export default { create, getImages, deleteImage, updateImagePosition };