import "./Management.css";

import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom';

import { Modal } from 'antd';
import { UserOutlined, CreditCardOutlined, LoginOutlined, LogoutOutlined, ScheduleOutlined } from '@ant-design/icons';

import UserContext from '../context/UsrContext';
import { payment } from "../services/accountService";

function Management(){
    const [visible, setVisible] = useState(false)

    const cxt = useContext(UserContext);
    const history = useHistory();

    const handleLogOut = () => {
        setVisible(false);
        cxt.onLogOut();
    };

    const handlePayment = async () => {
        try {
            const { data } = await payment();
            window.open(data, "_blank");
        } catch {
            setVisible(false);
        }
    }

    return <UserContext.Consumer>
        {usrCtx => <React.Fragment>
            <span className="link-icon" onClick={() => setVisible(true)}>
                <UserOutlined className="login-icon" />
            </span>
            <Modal title="Management" centered visible={visible} footer={null}
                onCancel={() => setVisible(false)}>
                <div className="man-btns">
                    {usrCtx.user ? <React.Fragment>
                        <div className="man-link-button" onClick={() => history.push("/reservations")}>
                            <ScheduleOutlined className="link-button-icon" />
                            <p>Reservations</p>
                        </div>
                        <div className="man-link-button" onClick={handlePayment}>
                            <CreditCardOutlined className="link-button-icon" /> 
                            <p>Payment</p> 
                        </div>
                        <div className="man-link-button" onClick={handleLogOut}>
                            <LogoutOutlined className="link-button-icon" /> 
                            <p>Log out</p> 
                        </div>
                    </React.Fragment> 
                    : <Link to="/login" className="man-link-button">
                        <LoginOutlined className="link-button-icon" /> 
                        <b>Log in</b> 
                    </Link>}
                </div>
            </Modal>
        </React.Fragment>}
    </UserContext.Consumer>
}

export default Management;