import React, { useState } from 'react';
import { Modal, Form, Input, InputNumber, notification } from 'antd';
import RegexUtils from "../utils/regex";
import { createReservation } from "../services/eventService";

export default ({ visible, eventId, onCancel, onOk }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);
            const { data } = await createReservation(eventId, values);
            form.resetFields();
            onOk(data);
            setLoading(false);
        } catch(error) {
            setLoading(false);

            if (error.response && error.response.status === 400)
                notification['warning']({ message: error.response.data });
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    return <Modal title="New Reservation" visible={visible} onCancel={handleCancel} onOk={handleOk} confirmLoading={loading}>
        <Form form={form} initialValues={{ reservationsQuantity: 1 }}>
            <Form.Item name="fullName" label="Full Name" rules={[{ required: true, message: "Please input the full name!"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Phone Number" rules={[
                { required: true, message: 'Please input the phone number!' },
                { pattern: RegexUtils.PHONE_NUMBER, message: "Please enter a valid phone number!" }
            ]}>
                <Input />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[
                { required: true, message: 'Please input the email!' },
                { type: "email", message: "Please enter a valid email!" }
            ]}>
                <Input />
            </Form.Item>
            <Form.Item name="reservationsQuantity" label="Quantity" rules={[{ required: true, message: 'Please input the quantity of reservations!' },]}>
                <InputNumber min={1} />
            </Form.Item>
        </Form>
    </Modal>;
}