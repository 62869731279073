import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { stripe } from '../config.json';

// TIP Don't call stripe more than once, don't call it in render method
const stripeLoad = loadStripe(stripe.key);

export default (props) => {
    return <Elements stripe={stripeLoad}>
        {props.children}
    </Elements>
};