import React, { useEffect, useState, createRef } from 'react';
import Photo from './Photo';
import { sections, addImage, deleteImage } from '../services/imageService';

import "../components/Gallery.css"
import { Skeleton, Button, Modal, Spin, message } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import UsrContext from "../context/UsrContext";

interface Image {
    id : string,
    url : string,
    sectionFlag: number
}

const antIcon = <LoadingOutlined style={{ fontSize: 48, color: "#4d1a05" }} spin />;

const inputFileRef = createRef<HTMLInputElement>();

const MAX_BYTES = 10 * 1000000; // MB
const ALLOWED_TYPES = [ "image/jpeg", "image/png", "image/x-png", "image/jpg" ];

function Gallery() {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([] as any);
    const [visible, setVisible] = useState(false);
    const [loadingSpin, setLoadingSpin] = useState(false);

    useEffect(() => {
        async function process() {
            setLoading(true);
            const { data } = await sections(4);
            setImages(data);
            setLoading(false);
        }

        process();
    }, []);

    const fileUploadHandler = () => {
        setLoadingSpin(true);

        const file : any = inputFileRef.current
            && inputFileRef.current.files
            && inputFileRef.current.files[0];

        const reader = new FileReader();
        reader.onload = ({ target }) => {
            const result = target!.result as string;
            const values = {
                name: file.name,
                base64Data: result.split(",")[1],
                type: result.split(";")[0].split(":")[1]
            };

            addImage(values.base64Data, values.type, values.name, 4).then((response) => {
                const temp = images;
                const nueva : Image = {
                    id: response.data.id,
                    url: result,
                    sectionFlag: response.data.sectionFlag
                };

                images.push(nueva);
                setImages(temp);
                handleCancel();
                message.success("Image added successfully!");
            }).finally(() => {
                setLoadingSpin(false);
            });
        };

        reader.readAsDataURL(file);
    };

    const fileSelectedHandler = (event: any) => {
        const input = event.target;
        const file = input.files[0];

        let errMsg;

        // Size
        if(file.size > MAX_BYTES)
            errMsg = `Image must be smaller than ${MAX_BYTES / 1000000} MB!`;

        // Type
        if(!ALLOWED_TYPES.includes(file.type))
            errMsg = 'You can only upload JPG/PNG file!';

        if(!errMsg) return;

        message.error(errMsg);
        input.value = "";
        input.files = null;
    };

    const handleCancel = () => {
        setVisible(false);
        const file : any = inputFileRef.current;
        file.value = "";
        file.files = null;
    };

    const deletePhoto = async (id : string) => {
        const temp = [...images]; 
        const index = images.map((i : Image) => i.id).indexOf(id);

        await deleteImage(id);
        temp.splice(index, 1);
        setImages(temp);
        message.success(`Image ${index + 1} deleted.`);
    }

    return (<UsrContext.Consumer>
        {(userCxt : any) => <div className="gallery">
            <Modal
                title="Add Image"
                visible={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key={1} disabled={loadingSpin} className="btnModalUpd" onClick={fileUploadHandler}>Upload</Button>
                ]}>
                <Spin indicator={antIcon} spinning={loadingSpin}>
                    <p className="pInput">
                        <input type="file" ref={inputFileRef} onChange={fileSelectedHandler} accept={ALLOWED_TYPES.join()} />
                    </p>
                </Spin>
            </Modal>
            <Skeleton loading={loading} active={true}>
                {userCxt.user && <div className="photoAddCover">
                    <Button className="btnAddPhoto" onClick={() => setVisible(true)}>
                        <PlusOutlined className="iconAddImage" />
                        <br />
                        add
                    </Button>
                </div>}
                {images.map((data : Image, index : number) => <Photo src={data.url} key={index} onDelete={() => deletePhoto(data.id)} />)}
            </Skeleton>
        </div>}
    </UsrContext.Consumer>);
};

export default Gallery;