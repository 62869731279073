import * as React from 'react';
import { Row, Col } from 'antd'; 

import "../components/History.css"

class History extends React.Component {
    
    render() { 
        return ( 
            <Row className="divContainer">
                <Col xs={24} md={12} className="divImg">
                    <img className="photoH imageR" src="/assets/images/guac.jpg" alt="history"/>
                </Col>
                <Col xs={24} md={12} className="divText textR">
                    <p>
                        <strong>Mi Pueblo Tacos y Tequila</strong> brings to Maine the flavors that 3 close friends grew up with, remembering how grand mother and mothers used to cook for us, not easy to replicate but close enough to serve mouthwatering dishes made with high quality fresh ingredients and lots of passion. Paired up with quality margaritas made with freshest ingredients and  only 100% blue agave tequila.
                    </p>
                </Col>
            </Row>
         );
    }
}
 
export default History;