import React, { useState, useEffect } from 'react';
import { Tabs, Button, Table, Popconfirm, message } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { ColumnHeightOutlined, DeleteOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { getImages, deleteImage, updateImagePosition } from "../services/imagesCarouselService"; 
import UploadCarousel from './UploadCarousel';

import './TableCarousel.css';

const { TabPane } = Tabs;

const DragHandle = sortableHandle(() => (
    <ColumnHeightOutlined style={{ cursor: 'pointer', color: '#4d1a05', fontSize: 'x-large' }} />
  ));

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);


function Carousel({handleManageCarousel}){
    const[dataImages, setDataImages] = useState([]);

    useEffect(() => {

        async function process(){
          const { data } = await getImages();
          // Order images by position
          data.sort((a, b) => {
            return a.position - b.position;
          });
          setDataImages(data);
        }
        process();
        
    },[])

    const handleNewImage = (imagen) =>{
        async function addImage(){
          const newData = [...dataImages];
          newData.unshift(imagen);
          await setDataImages(newData);
          handleManageCarousel(imagen,"A");
        }
        addImage();
    }

    const handleDelete = (key) => {
        
        async function deleteImg(){
          await deleteImage(key).then((response) => {
            message.success("Deleted image.");
            const dataS = [...dataImages];
            setDataImages(dataS.filter((item) => item.id !== key));
            const item = {
              id : key
            }
            handleManageCarousel(item, "D");
          });
        }
        deleteImg();
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const dataS = [...dataImages];
        if (oldIndex !== newIndex) {
          async function updImgPos(){
            await updateImagePosition(dataS[oldIndex].id, dataS[newIndex].position).then((response) => {
              message.success("Changed position.");
              const newData = arrayMove([].concat(dataS), oldIndex, newIndex).filter(el => !!el);
              setDataImages( newData );
              const item = {
                oldIndex : oldIndex,
                newIndex : newIndex
              }
              handleManageCarousel(item,"O");
            });
          }
          updImgPos();
        }
    };
    
    const DraggableContainer = props => (
        <SortableContainer
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
    );
    
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const dataS = [...dataImages];
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataS.findIndex(x => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const columns = [
        {
            title: '',
            dataIndex: 'sort',
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
          title: '',
          dataIndex: 'urlThumbnail',
          className: 'drag-visible',
          align: 'center',
          render: (url) => (<img alt="img-carousel" src={url} />)
        },
        {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            render: (_, record) =>
            dataImages.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
                  <Button className="btnModalUpd" shape="circle" icon={<DeleteOutlined />}/>
                </Popconfirm>
              ) : null,
        },
    ];

    return(
        <React.Fragment>

            <Tabs defaultActiveKey="1" type="card" size={'small'} centered>
                <TabPane tab="Edit Images" key="1">
                    <Table
                        pagination={false}
                        dataSource={dataImages}
                        columns={columns}
                        scroll={{ y: 300 }}
                        rowKey="id"
                        components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                        }}
                    />
                    
                </TabPane>
                <TabPane tab="Add Images" key="2">
                    <UploadCarousel handleNewImage={handleNewImage}/>
                </TabPane>
            </Tabs>

        </React.Fragment>
    )

}

export default Carousel;