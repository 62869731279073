/*
    Reads the content of a File or Blob and returns it as a base64 encoded string.
 *  @param blob - The file or Blob to read.
 */
const getBase64 = (blob : Blob) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    if (!blob) return resolve(null);

    try {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result));
        reader.readAsDataURL(blob);
    } catch(e) {
        reject(e);
    }
});

export default { getBase64 };