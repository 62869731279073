import React, { useContext, useState } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import UsrContext from "../context/UsrContext";

import "../components/Login.css"

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

function Login(){
    const [loading, setLoading] = useState(false);

    const userContext: any = useContext(UsrContext);

    const handleLogin = async ({ username, password } : { username : string, password : string}) => {
        setLoading(true);

        try {
            await userContext.onLoggedIn(username, password);
        } finally {
            setLoading(false);
        }
    };

    return(<Row className="container" justify="center">
        <Col className="divContent" xs={20} md={20} offset={6}>
            <Form
            {...layout}
            name="basic"
            onFinish={handleLogin}
            >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" style={{color: '#4d1a05' }} />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input
                    prefix={<LockOutlined className="site-form-item-icon" style={{color: '#4d1a05' }}/>}
                    type="password"
                    placeholder="Password"
                    />
                </Form.Item>
                <Form.Item >
                    <Button className="btnSubmit" loading={loading} htmlType="submit" shape="round" size="large">
                    Submit
                    </Button>
                </Form.Item>
            </Form>
        </Col>
    </Row>);
}

export default Login;