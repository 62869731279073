import http from "./httpService";

const endPoint = "/api/v1/Events";

export function getCurrentEvent() {
    return http.get(`${endPoint}/Current`);
}

export function getEvent(id) {
    return http.get(`${endPoint}/${id}`);
}

export function getEvents() {
    return http.get(endPoint);
}

export function getReservations(eventId) {
    return http.get(`${endPoint}/${eventId}/reservations`)
}

export function createEvent(data) {
    return http.post(endPoint, data);
}

export function createReservation(eventId, data) {
    return http.post(`${endPoint}/${eventId}/reservations`, data);
}

export function reserve(id, data) {
    return http.post(`${endPoint}/${id}/reserve`, data);
}

export function confirm(id, stripePaymentIntentId, stripePaymentMethodId) {
    return http.post(`${endPoint}/${id}/Confirm`, { stripePaymentIntentId, stripePaymentMethodId });
}

export function cancelReservation(eventId, reservationId) {
    return http.patch(`${endPoint}/${eventId}/reservations/${reservationId}/Cancel`);
}

export function applyReservation(eventId, reservationId, code) {
    return http.patch(`${endPoint}/${eventId}/reservations/${reservationId}/code/${code}`, { applied: true });
}

export function updateEvent(id, event) {
    return http.put(`${endPoint}/${id}`, event);
}

export default { getEvent, reserve, confirm, applyReservation };