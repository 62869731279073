import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import moment from "moment";

import { Col, Row, Form, Input, InputNumber, DatePicker, Checkbox, Skeleton, Button, notification } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';

import Upload from "./common/Upload";
import FileUtils from "../utils/file";

import { getEvent, updateEvent } from './../services/eventService';

const { RangePicker } = DatePicker;

export default () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [okLoading, setOkLoading] = useState(false);
    const [event, setEvent] = useState(null);    

    useEffect(() => {
        async function process() {
            setLoading(true);

            try {
                const { data } = await getEvent(id);
                setEvent({
                    ...data,
                    fromTo: [
                        moment(data.from + "Z"),
                        moment(data.to + "Z")
                    ]
                });
                setLoading(false);
            } catch {
                setLoading(false);
            }
        }

        process();
    }, [id]);

    const handleFinish = async (values) => {
        setOkLoading(true);
        
        try {
            const request = {
                ...values,
                from: values.fromTo[0].startOf('day'),
                to: values.fromTo[1].endOf('day'),
                image: values.image instanceof File ? await FileUtils.getBase64(values.image) : values.image
            };

            await updateEvent(id, request);
            setOkLoading(false);
            notification['success']({ message: 'Edit event', description: "The event was successfully updated." });
        } catch {
            setOkLoading(false);
        }
    };

    return <Row justify="center">
    <Col xs={24} sm={20} className="mainContainer">
        <h1 className="title">
            <ArrowLeftOutlined style={{ fontSize: "20px", marginRight: "10px", cursor: "pointer" }}
                               onClick={() => history.goBack()} />
            Event
        </h1>
        <hr className="hr-title" style={{ marginBottom: "30px" }} />
        <Row gutter={[16, 24]}>
            {loading ? <Skeleton active /> : <Col xs={24} md={18} lg={12}> 
                <Form form={form} initialValues={event} onFinish={handleFinish}>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please input the event's name!"}]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        name="fromTo"
                        label="Dates"
                        extra="Days on which the event will be available."
                        rules={[{ required: true, message: "Please input a range date!"}]}>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item name="reservationsLimit" label="Reservation Limit" rules={[{ required: true, message: "Please input the limit of reservations!"}]}>
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item name="price" label="Price" rules={[{ required: true, message: "Please input the price!"}]}>
                        <InputNumber formatter={(value) => `$ ${value}`} min={0} />
                    </Form.Item>
                    <Form.Item name="showAnnouncement" valuePropName="checked">
                        <Checkbox>Show Announcement</Checkbox>
                    </Form.Item>
                    <Form.Item name="image" label="Image">
                        <Upload src={event && event.image} />
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" loading={okLoading}>Save</Button>
                    </Form.Item>
                </Form>
            </Col>}
        </Row>
    </Col>
</Row>;
}