import * as React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import NavMenu from './NavMenu';
import { WhatsAppOutlined, FacebookFilled, InstagramOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
import yelpIcon from '@iconify-icons/cib/yelp';
import { socialMedia } from "../config.json";
import Management from './Management'

import './Layout.css';

export default (props: { pathname : string, children?: React.ReactNode }) => (
<React.Fragment>
    <Row justify="center" >
        <Col xs={24} md={20} >
            <div className="layout-top-banner">
            </div>
            <div>
                <Link to="/">
                    <img className="layout-logo" alt="logo" src="./assets/images/logo-layout.png" />
                </Link>
            </div>
            <NavMenu pathname={props.pathname}/>
            <div className="layout-content">
                {props.children}
            </div>
            <div className="layout-footer">
                <p>
                    <Management/>
                </p>
                <p>
                    <a href={socialMedia.facebook}><FacebookFilled className="social-icon" /></a>
                    <a href={socialMedia.instagram}><InstagramOutlined className="social-icon" /></a>
                    <a href={socialMedia.whatsapp}><WhatsAppOutlined className="social-icon" /></a>
                    <a href={socialMedia.yelp}>
                        <span style={{ margin: "5px" }}>
                            <Icon icon={yelpIcon} height={30}/>
                        </span>
                    </a>
                </p>
                <p>© Copyright {new Date().getFullYear()} Mi Pueblo Tacos y Tequila</p>
                <p>powered by <a href="https://itfortal.com" style={{ textDecoration: "underline"}} >IT Fortal ®</a></p>
            </div>
        </Col>
    </Row>
</React.Fragment>
);
