import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Table, Select, Button, Col, Input, Row, notification, Tag, Tooltip } from 'antd';
import { PlusCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, EditOutlined } from '@ant-design/icons';

import { normalized } from '../utils/string';
import DateUtils from '../utils/date';

import "./Reservations.css";

import EventCreateModal from "./EventCreateModal";
import ReservationCreateModal from "./ReservationCreateModal";
import { getEvents, getReservations, cancelReservation, applyReservation } from "../services/eventService";

const { Option } = Select;

const STATUSES = {
    APPLIED: { text: "APPLIED", color: "success" },
    CANCELLED: { text: "CANCELLED", color: "error" },
    CONFIRMED: { text: "CONFIRMED", color: "processing" }
};

const DATE_FORMAT = "MMM Do YYYY, h:mm a";

function Reservations(){
    const [tableLoading, setTableLoading] = useState(false);
    const [modalVisibleNewEvent, setModalVisibleNewEvent] = useState(false);
    const [modalVisibleNewReservation, setModalVisibleNewReservation] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [eventsLoading, setEventsLoading] = useState(false);
    const [eventSelected, setEventSelected] = useState(null);
    const [events, setEvents] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [reservationLoading, setReservationLoading] = useState(null);
    const [codeLoading, setCodeLoading] = useState(null);

    const history = useHistory();

    useEffect(() => {
        async function process() {
            setEventsLoading(true);

            try {
                const { data } = await getEvents();
                setEvents(data);
                setEventsLoading(false);
            } catch {
                setEventsLoading(false);
            }
        }

        process();
    }, []);

    const handleClickApply = async (reservationId, code) => {
        setCodeLoading(code);

        try {
            await applyReservation(eventSelected, reservationId, code);

            function update(array, setFn) {
                const tmp = [...array];
                const i = tmp.find(r => r.code === code);
                i.applied = true;
                i.status = STATUSES["APPLIED"].text;
                setFn(tmp);
            }

            // Updates the reservations and the data filtered (used to fill the table) to
            update(reservations, setReservations);
            update(filtered, setFiltered);

            setCodeLoading(null);
        } catch {
            setCodeLoading(null);
        }
    }

    const handleClickCancel = async (reservationId) => {
        setReservationLoading(reservationId);

        try {
            const { data } = await cancelReservation(eventSelected, reservationId);

            function update(array, setFn) {
                const tmp = [...array];
                tmp.forEach(r => {
                    if (r.id !== reservationId) return;

                    r.cancelled = data.cancelled;
                    r.status = STATUSES["CANCELLED"].text;
                });
                setFn(tmp);
            }

            // Updates the reservations and the data filtered (used to fill the table) to
            update(reservations, setReservations);
            update(filtered, setFiltered);

            setReservationLoading(null);
        } catch {
            setReservationLoading(null);
        }
    }

    const handleOkModalNewEvent = (event) => {
        notification["success"]({
            message: 'New event',
            description:
              `The ${event.name} event has been created successfully.`,
        });
        setEvents([...events, event]);
        setModalVisibleNewEvent(false);
    }

    const handleClickModalNewReservation = () => {
        if(eventSelected) {
            setModalVisibleNewReservation(true);
        } else {
            notification['warning']({ message: 'New reservation', description: "First select an event." })
        }
        // TODO Validar que no sea fuera fecha
        // TODO Validar que no supere el limite
    }

    const handleOkModalNewReservation = (reservation) => {
        const res = reservation.codes.map(c => ({
            ...reservation,
            code: c.code,
            applied: c.applied,
            // Calculate status
            status: c.applied ? STATUSES.APPLIED.text : reservation.cancelled ? STATUSES.CANCELLED.text : STATUSES.CONFIRMED.text,
        }));

        setReservations([...reservations, ...res]);
        setFiltered([...filtered, ...res]);
        setModalVisibleNewReservation(false);
        notification['success']({ message: "Reservation created", description: "An email was send with reservations details." });
    }

    const handleChangeEvent = async (value) => {
        setEventSelected(value);
        setTableLoading(true);
        setSearchValue(null);

        try {
            const { data } =  await getReservations(value);

            // Reservations by codes
            const items = data.flatMap(r => r.codes.map(c => ({
                ...r,
                code: c.code,
                applied: c.applied,
                // Calculate status
                status: c.applied ? STATUSES.APPLIED.text : r.cancelled ? STATUSES.CANCELLED.text : STATUSES.CONFIRMED.text,
            })));

            setReservations(items);
            setFiltered(items);
            setTableLoading(false);
        } catch {
            setTableLoading(false);
        }
    }

    const handleSearch = ({ target }) => {
        setSearchValue(target.value);

        if (target.value === "") {
            setFiltered(reservations);
            return;
        }

        const tmp = reservations.filter(entry => normalized(entry.fullName).includes(normalized(target.value))
            || normalized(entry.phoneNumber).includes(normalized(target.value))
            || normalized(entry.code).includes(normalized(target.value))
        );

        setFiltered(tmp);
    };

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            width: "80px",
            render: (val) => <Tag color="default">{val.toUpperCase()}</Tag>
        },
        { title: 'Full Name', dataIndex: 'fullName' },
        { title: 'Phone number', dataIndex: 'phoneNumber', width: "180px" },
        {
            title: 'Date',
            dataIndex: 'created',
            width: "200px",
            defaultSortOrder: 'descend',
            render: (val) => DateUtils.toLocalFormat(val, DATE_FORMAT),
            sorter: (a, b) => new Date(a.created) - new Date(b.created),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: "130px",
            render: (val, record) => {
                const tag = <Tag color={STATUSES[val].color}>{STATUSES[val].text}</Tag>;
                return val === "CANCELLED" ? <Tooltip title={DateUtils.toLocalFormat(record.cancelled, DATE_FORMAT)}>{tag}</Tooltip> : tag;
            },
            filters: Object.keys(STATUSES).map(s => ({ text: s, value: s })),
            onFilter: (value, record) => record.status.indexOf(value) === 0
        },
        {
            title: '',
            dataIndex: 'status',
            align: 'center',
            render: (value, record) => {
                if ([STATUSES["APPLIED"].text, STATUSES["CANCELLED"].text].includes(value)) return "";

                if (reservationLoading === record.id || codeLoading === record.code) return <LoadingOutlined />;

                const cancelBtn = <Button type="primary" size="small" danger icon={<CloseCircleOutlined />}
                                          onClick={() => handleClickCancel(record.id)}>Cancel
                </Button>;

                return (<React.Fragment>
                    {!record.hasPayment && cancelBtn}
                    <Button type="primary" size="small" icon={<CheckCircleOutlined />}
                            onClick={() => handleClickApply(record.id, record.code)}>Apply
                    </Button>
                </React.Fragment>)
            }
        }
    ];

    return <Row justify="center">
        <ReservationCreateModal visible={modalVisibleNewReservation} eventId={eventSelected}
            onOk={handleOkModalNewReservation} onCancel={() => setModalVisibleNewReservation(false)}/>
        <EventCreateModal visible={modalVisibleNewEvent} onOk={handleOkModalNewEvent}
                          onCancel={() => setModalVisibleNewEvent(false)}/>
        <Col xs={24} sm={20} className="mainContainer">
            <h1 className="title">Reservations</h1>
            <hr className="hr-title" style={{ marginBottom: "30px" }} />
            <Row gutter={[16, 24]}>
                <Col xs={22} sm={16} md={10}>
                    <Select loading={eventsLoading} style={{ width: '100%' }} onChange={handleChangeEvent}
                            placeholder="Event...">
                        {events.map(e => <Option key={e.id} value={e.id}>{e.name}</Option>)}
                    </Select>
                </Col>
                <Col xs={2} sm={3} md={2}>
                    <Tooltip title="Edit Event">
                        <EditOutlined onClick={() => eventSelected && history.push(`/event/${eventSelected}`)} />
                    </Tooltip>
                </Col>
                {/*TODO Event information*/}
                <Col xs={24} md={12}>
                    <Row gutter={[16, 16]} justify="end">
                        <Col>
                            <Button className="btnSubmit" type="primary" icon={<PlusCircleOutlined/>}
                                    onClick={() => setModalVisibleNewEvent(true)}>
                                New Event
                            </Button>
                        </Col>
                        <Col>
                            <Button className="btnSubmit" type="primary" icon={<PlusCircleOutlined/>}
                                    onClick={handleClickModalNewReservation}>
                                New Reservation
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Input placeholder="Search" value={searchValue} onChange={handleSearch} />
                </Col>
                <Col span={24}>
                    <Table style={{ overflowX: "auto" }} rowKey="code" dataSource={filtered} columns={columns}
                           loading={tableLoading}/>
                </Col>
            </Row>
        </Col>
    </Row>
}

export default Reservations;