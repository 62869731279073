import "./ReservationPay.css";

import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Form, Input, InputNumber, Modal, Skeleton, Row, Col, Alert, notification } from "antd";
import { confirm, getEvent, reserve } from "../services/eventService";
import { useParams } from "react-router";
import RegexUtils from "../utils/regex";

export default () => {
    const stripe = useStripe();
    const elements = useElements();
    const { id } = useParams();
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [card, setCard] = useState({ complete: false, empty: true });
    const [total, setTotal] = useState(0);
    const [confirmation, setConfirmation] = useState();
    const [showModal, setShowModal] = useState(false);

    const cardElementOptions = {
        hidePostalCode: true
    };

    useEffect(() => {
        async function process() {
            const { data } = await getEvent(id);
            setEvent({
                ...data,
                // Determines if the event is out of date range
                closed: new Date() < new Date(`${data.from}Z`) || new Date() > new Date(`${data.to}Z`)
            });
            setLoading(false);
            setTotal(data.price);
        }

        process();
    }, [ id ]);

    const handleSubmit = async (values) => {
        // Validate card element
        if (card.empty) {
            // Set card error to form
            form.setFields([{ name: "card", errors: [ "No information card provided!" ], value: "" }]);
            return;
        } else if(!card.complete) {
            form.setFields([{ name: "card", errors: [ card.error.message ], value: "" }]);
            return;
        }

        setProcessing(true);

        try {
            // Create a payment intent on the server
            const { data } = await reserve(event.id, values);

            // Create Payment method
            const { paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
                billing_details: { name: values.fullName }
            });

            // Confirm payment
            const { data: confirmed } = await confirm(event.id, data.stripePaymentIntentId, paymentMethod.id)

            setProcessing(false);

            // It also resets the input card
            form.resetFields();

            setConfirmation(confirmed);
            setShowModal(true);
        }catch(error) {
            setProcessing(false);

            if (error.response && error.response.status === 400)
                notification['warning']({ message: error.response.data });
        }
    };

    const handleCardChange = (event) => {
        setCard(event);

        // Set card error to form
        form.setFields([{
            name: "card",
            value: "",
            errors: event.complete ? null : [ event.error?.message ?? "Card information is incomplete!" ]
        }]);
    };

    const handleNumberChange = (value) => setTotal(value * event.price);

    return <div className="checkout-container">
        <Modal
            title="Reservation Confirmed"
            visible={showModal}
            closable={false}
            footer={[
                <Button key="close" type="primary" onClick={() => history.push("/")} >Close</Button>,
            ]}>
            <p>Your reservation has been confirmed!</p>
            {confirmation && <React.Fragment>
                <h2>Your reservation codes:</h2>
                <Row gutter={[16, 16]} justify="center" style={{ marginBottom: "20px" }}>
                    {confirmation.codes.map(c => <Col key={c.code} xs={24} sm={12} md={8}>
                        <div className="code-container">{c.code}</div>
                    </Col>)}
                </Row>
                <Alert type="info" message="" description="You will receive an email with your reservation details, check your spam. We recommend you save it as a screenshot for future clarification" showIcon />
            </React.Fragment>}
        </Modal>
        {loading ? <React.Fragment><Skeleton.Image /><Skeleton active /></React.Fragment>
            : <React.Fragment>
            <div>
                {event.image && <img alt="event" src={event.image} />}
                <h2>{event.name}</h2>
                <p className="event-description">{event.description}</p>
            </div>{event.closed ? <p className="event-state-title">Event Closed</p>
                : event.soldOut ? <p className="event-state-title">Event Sold out</p> :
            <Form form={form} onFinish={handleSubmit} initialValues={{ reservationsQuantity: 1 }}>
                <Form.Item
                    label="Full name"
                    name="fullName"
                    rules={[{ required: true, message: 'Please input your full name!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Phone number"
                    name="phoneNumber"
                    rules={[
                        { required: true, message: 'Please input your 10 digits phone number!' },
                        { pattern: RegexUtils.PHONE_NUMBER, message: "Please enter a valid phone number!" }
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Please input your email!' },
                        { type: 'email', message: "Please enter a valid email!" },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Number of reservations"
                    name="reservationsQuantity"
                    rules={[{ required: true }]}>
                    <InputNumber min={1} max={10} onChange={handleNumberChange} />
                </Form.Item>
                <Form.Item name="card">
                    <CardElement options={cardElementOptions} onChange={handleCardChange} />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" block loading={processing}>{`Pay $${total}`}</Button>
                </Form.Item>
            </Form>}
        </React.Fragment>}
    </div>;
}