import React, { useState } from 'react';
import { Modal, Form, Input, InputNumber, DatePicker, Checkbox } from 'antd';

import Upload from "./common/Upload";
import FileUtils from "../utils/file";
import { createEvent } from "../services/eventService";

const { RangePicker } = DatePicker;

export default ({ visible, onCancel, onOk }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);

            const request = {
                ...values,
                // fromTo 0, 1 are moment js values that are sent o server in UTC
                from: values.fromTo[0].startOf('day'),
                to: values.fromTo[1].endOf('day'),
                image: await FileUtils.getBase64(values.image)
            };

            // API Call - Create Event
            const { data } = await createEvent(request);
            form.resetFields();
            onOk(data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    return <Modal title="New Event" visible={visible} onCancel={handleCancel} onOk={handleOk} confirmLoading={loading}>
        <Form form={form} initialValues={{ showAnnouncement: true }}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please input the event's name!"}]}>
                <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
                <Input.TextArea />
            </Form.Item>
            <Form.Item
                name="fromTo"
                label="Dates"
                extra="Days on which the event will be available."
                rules={[{ required: true, message: "Please input a range date!"}]}>
                <RangePicker />
            </Form.Item>
            <Form.Item name="reservationsLimit" label="Reservation Limit" rules={[{ required: true, message: "Please input the limit of reservations!"}]}>
                <InputNumber min={1} />
            </Form.Item>
            <Form.Item name="price" label="Price" rules={[{ required: true, message: "Please input the price!"}]}>
                <InputNumber formatter={(value) => `$ ${value}`} min={0} />
            </Form.Item>
            <Form.Item name="showAnnouncement" valuePropName="checked">
                <Checkbox>Show Announcement</Checkbox>
            </Form.Item>
            <Form.Item name="image" label="Image">
                <Upload />
            </Form.Item>
        </Form>
    </Modal>;
}