import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Carousel, Row, Col, Typography, Modal, Tooltip, Button, Spin } from 'antd';
import { PhoneTwoTone, EditOutlined } from '@ant-design/icons';
import IMask from 'imask';
import { getImages } from '../services/imagesCarouselService';
import './Home.css';
import { mapUrl, phone } from '../config.json';
import CarouselOptions from '../components/Carousel';
import UserContext from '../context/UsrContext';
import arrayMove from 'array-move';
import { getCurrentEvent } from './../services/eventService';

const { Title } = Typography;

const text = <span>Manage Images</span>;

interface CurrentEvent {
  id: number;
  name: string;
  description: string;
  image: string;
  price: number;
  showAnnouncement: boolean;
}

function Home() {
  const [modal, setModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventModal, setEventModal] = useState(true);
  const [currentEvent, setCurrentEvent] = useState<CurrentEvent | null>(null);

  const cxt : any = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if(new Date() < new Date("2022-03-31T00:00:00Z")) setModal(true);

    async function process(){
      setLoading(true);
      const { data } = await getImages();

      // Order images by position
      data.sort((a : any, b : any) => a.position - b.position);

      setImages(data);
      setLoading(false);
    }

    async function currentEvent(){
        const { data } = await getCurrentEvent();
        setCurrentEvent(data);
    }

    process();
    currentEvent();
  }, []);

    const handleCancel = () => {
        setVisible(false);
    };

    const showModal = () => {
        setVisible(true);
    }

  const handleManageCarousel = (item: any, action: string) =>{
    const dataS: any = [...images];

    switch(action) {
      case "A":
        dataS.unshift(item);
        setImages(dataS);
        break;
      case "O":
        const dataO = arrayMove([].concat(dataS), item.oldIndex, item.newIndex).filter(el => !!el);
        setImages(dataO);
        break;
      case "D":
        const dataD =dataS.filter((data: any) => data.id !== item.id);
        setImages(dataD);
        break;
    }
    
  }

  return (<>
    <Modal title="Important announcement" visible={modal} footer={null} onCancel={() => setModal(false)}>
      <h1 style={{ textAlign: "center", marginTop: "25px" }}>Save the date: March 30th. 5:30 pm</h1>
      <h2 style={{ textAlign: "center", marginTop: "25px" }}>Tequila dinner, hosted by Patron Spirits. 4 course meal paired up with Patron brand tequilas, menu will be finalized soon.</h2>
      <h2 style={{ textAlign: "center", marginTop: "25px" }}>Stay tuned in!</h2>
      <img style={{marginTop: 0}} alt="announcement-img" src="/assets/images/logo-tequila-patron.png"/>
    </Modal>
    {currentEvent && currentEvent.showAnnouncement && <Modal title="Event announcement" visible={eventModal} footer={null} onCancel={() => setEventModal(false)}>
      {currentEvent.image && <img style={{marginTop: 0}} alt="announcement-img" src={currentEvent.image}/>}
      <h1 style={{ textAlign: "center", marginTop: "25px" }}>{currentEvent.name}</h1>
      <h2 style={{ textAlign: "center", marginTop: "25px" }}>{currentEvent.description}</h2>
      <Button type="primary" size="large" block onClick={() => history.push(`/reservations-pay/${currentEvent.id}`)}>RESERVE NOW!</Button>
    </Modal>}
    <Modal title="Manage Images" visible={visible} onCancel={handleCancel} footer={null} width={600}>
      <CarouselOptions handleManageCarousel={handleManageCarousel}/>
    </Modal>
    <Row>
      <Col span={24}>
        {loading && <div style={{ height: "400px", paddingTop: "150px", textAlign: "center" }}>
          <Spin size="large" />
        </div>}
        {!loading && images.length && <Carousel autoplay={true} speed={100}>
          {images.map((i: any) => (<img key={i.id} alt={i.position} src={i.urlImage} />))}
        </Carousel>}
      </Col>
      {cxt.user && <Col span={24}>
        <Tooltip placement="top" title={text}>
          <Button onClick={showModal} className="btnDeleteImg" type="dashed" shape="circle" icon={<EditOutlined />} />
        </Tooltip>
      </Col>}
    </Row>
    <Row>
      <Col span={24} style={{ padding: "30px" }}>
      <iframe title="video_01" src="https://player.vimeo.com/video/552148481?title=0&byline=0&portrait=0" frameBorder="0" allowFullScreen></iframe>
      </Col>
      <Col span={24} style={{ padding: "30px" }}>
        <iframe title="video_02" src="https://player.vimeo.com/video/567594049?title=0&byline=0&portrait=0" frameBorder="0" allowFullScreen></iframe>
      </Col>
    </Row>
    <Row>
      <Col xs={24} md={14} style={{ padding: "30px" }}>
        <iframe title="location" src={mapUrl}></iframe>
      </Col>
      <Col xs={24} md={10} style={{ padding: "30px" }}>
        <Title level={4}>Mi Pueblo Tacos y Tequila Restaurant</Title>
        <p>500 Mariner Way (Biddeford crossing)</p>
        <p>Biddeford, ME 04005</p>
        <p>
          <a href={`tel:${phone.number}`}>
            <PhoneTwoTone twoToneColor="#4d1a05" />
            {" " + IMask.createMask({ mask: phone.mask }).resolve(phone.number)}
          </a>
        </p>
        <p><a href="mailto:contact@mipueblotacosytequila.com">contact@mipueblotacosytequila.com</a></p>
      </Col>
    </Row>
  </>);
}

export default Home;