import "./Upload.css";

import React, { useState } from 'react';
import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import FileUtils from "../../utils/file";

export default ({ src, onChange }) => {
    const [loading, setLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState(src);

    const handleChange = async (info) => {
        setImgSrc("");
        setLoading(true);

        setImgSrc(await FileUtils.getBase64(info.file));
        setLoading(false);
        onChange(info.file);
    };

    return <Upload
        name="image"
        showUploadList={false}
        className="app-upload"
        onChange={handleChange}
        beforeUpload={() => false }>
        {imgSrc ? <img src={imgSrc} alt="event" /> : <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div>Upload</div>
        </div>}
    </Upload>;
}