import * as React from 'react';
import { Drawer, List } from 'antd';
import { Link } from 'react-router-dom';
import { MenuFoldOutlined } from '@ant-design/icons';

import './NavMenu.css';

export default class NavMenu extends React.PureComponent<{ pathname: string }, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    private list = [
        { text: "Home", to: "/" },
        { text: "Menu", to: "/menu" },
        { text: "Gallery", to: "/gallery", style: { marginRight: "100px"} },
        { text: "History", to: "/history", style: { marginLeft: "100px"} },
        { text: "Reviews", to: "/reviews" }
    ];

    private onClose = () => this.setState({ isOpen: false });

    private onOpen = () => this.setState({ isOpen: true });

    private isPath = (to: string) => to === this.props.pathname;

    public render() {
        return (
<div className="layout-menu">
    <MenuFoldOutlined className="menu-icon" onClick={this.onOpen} />
    <Drawer
        placement="left"
        closable={false}
        onClose={this.onClose}
        visible={this.state.isOpen} >
            <List
                size="large"
                dataSource={this.list}
                renderItem={item => (<List.Item className="drawer-menu-item" onClick={this.onClose}>
                    <Link className={this.isPath(item.to) ? "active" : ""} to={item.to}>{item.text}</Link>
                </List.Item>)}
            />
    </Drawer>
    <ul>
        {this.list.map((c, i) => <li key={i} style={c.style}>
                <Link to={c.to} className={this.isPath(c.to) ? "active" : ""}>{c.text}</Link>
        </li>)}
        {/* Adds an extra <li> if items in the menu items are odd */}
        {this.list.length % 2 !== 0 && <li></li>}
    </ul>
</div>
        );
    }
}
