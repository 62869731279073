import React from "react";
import { Redirect, Route } from "react-router";

import UsrContext from "../../context/UsrContext";

interface ProtectedRouteProps {
    path: string,
    component: any,
    render?: (props: any) => JSX.Element
}

function ProtectedRoute ({ path, component: Component, render, ...rest} : ProtectedRouteProps) {
    return (<UsrContext.Consumer>
        {(ctx : any) => <Route path={path} {...rest} render={props => ctx.user 
            ? Component ? <Component {...props} /> : render?.(props)
            : <Redirect to="/login" />} />}
    </UsrContext.Consumer>);
};

export default ProtectedRoute;