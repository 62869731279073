import * as React from 'react';
import { Button, message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { create } from "../services/imagesCarouselService"; 

function UploadCarousel({handleNewImage}) {
    const [fileList, setFileList] = React.useState([]);
    const [uploading, setUploading] = React.useState(false);

    const handleUpload = () => {
        setUploading(true);
        fileList.forEach(file => {
            const reader = new FileReader();
            const image = new Image(); 
            
            reader.readAsDataURL(file);
            reader.onload = ({ target }) => { 
                const result = target.result; 
                image.src = result;
                const values = { 
                    name: file.name, 
                    size: file.size,
                    base64Data: result.split(",")[1], 
                    type: result.split(";")[0].split(":")[1] 
                };

                const CreateImageCarousel = {
                    image : values.base64Data,
                    mediaType : values.type,
                    name : values.name
                }

                async function addImg(){
                    try {
                        const { data } = await create(CreateImageCarousel);
                        handleNewImage(data);
                        setFileList([]);    
                        message.success("Added image.");
                    } finally {
                        setUploading(false);
                    }
                }
                addImg();
            }; 
            // image.onload = async function() {
            //     const ancho = image.width;
            //     const alto = image.height;
            //     console.log(alto)
            //     console.log(ancho)
            // };
        });
      };

    const props = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
         
        },
        beforeUpload: (newFile ) => {
            const oldList = [...fileList];
            oldList.push(newFile);
            setFileList(oldList);
            return false;
        },
        fileList,
        listType: "picture-card",
        onPreview: file => {
            let src = file.url;
            if (!src) {
              src = new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
              });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        },
        onChange: ({ fileList: newFileList }) => {
            setFileList(newFileList);
        }
      };

    return(
        <div style={{display:"grid"}}>
            <ImgCrop grid aspect={8/3}>
                <Upload {...props}>
                    {fileList.length < 1 && '+ Upload'}
                </Upload>
            </ImgCrop>

            <Button
            className="btnModalUpd"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
            >
            {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
        </div>
    );
}

export default UploadCarousel;