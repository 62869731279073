import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { message } from "antd";

// Components
import ProtectedRoute from './components/common/ProtectedRoute';
import LayoutedRoute from './components/LayoutedRoute';
import Layout from './components/Layout';
import Home from './components/Home';
import Gallery from './components/Gallery';
import History from './components/History';
import Login from "./components/Login";
import LoginLayout from "./components/LoginLayout";
import Reviews from "./components/Reviews";
import ReservationPay from "./components/ReservationPay";
import StripeWrapper from "./components/StripeWrapper";
import Reservations from "./components/Reservations";
import Event from "./components/Event";

import UsrContext from "./context/UsrContext";

import { menuUrl } from "./config.json";

import { setAuthorization, deleteAuthorization } from "./services/httpService";
import { login } from './services/authService';

import './custom.css';

function App(){
    const [user, setUser] = React.useState(undefined);
    const history = useHistory();

    useEffect(() => {
        const token = localStorage.getItem("token");
        
        if(!token) return; 
        
        // Sets user
        setUser(jwt_decode(token));

        // Sets authorization header brearer token
        setAuthorization(token);
    }, []);

    // Login
    const handleLogin = async (username: string, password: string) => {
        let response;

        try {
            response = await login(username, password);
        } catch (error) {
            message.warning("Invalid user name or password");
            return;
        }

        const token = response.data.token;
        
        // Sets authorization header brearer token
        setAuthorization(token);
        
        // Set LocalStorage
        localStorage.setItem("token", token);

        const user: any = jwt_decode(token);
        setUser(user);
        history.push('/');
    };

    // Logout
    const handleLogout = () => {
        // Elimina token de local storage
        localStorage.removeItem("token");

        // Remove Authorization header
        deleteAuthorization();

        setUser(undefined);

        // Return to Home
        history.push('/');
    };

    return(<UsrContext.Provider value={{ user: user, onLoggedIn: handleLogin, onLogOut: handleLogout }}>
        <Switch>
            <ProtectedRoute path="/event/:id" component={Event} />
            <Route path="/menu/view" render={() => (window.location.href = menuUrl)} />
            <Route path="/menu" render={() => (window.location.href = menuUrl)} />
            <Route path="/reservations-pay/:id" render={() => <StripeWrapper><ReservationPay /></StripeWrapper>} />
            <ProtectedRoute path="/reservations" component={Reservations} />
            <LayoutedRoute path="/reviews" layout={Layout} component={Reviews} />
            <LayoutedRoute path="/gallery" layout={Layout} component={Gallery} />
            <LayoutedRoute path="/history" layout={Layout} component={History} />
            <LayoutedRoute path="/login" layout={LoginLayout} component={Login} />
            <LayoutedRoute path="/" layout={Layout} component={Home} />
        </Switch>
    </UsrContext.Provider>);
}

export default App;