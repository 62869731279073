import * as React from 'react';
import { Row, Col } from 'antd';

import './LoginLayout.css';

export default (props: { pathname : string, children?: React.ReactNode }) => (
<Row justify="center" className="loginContainer">
        
        <Col xs={24} md={12} >
            <div className="login-banner radiusBanner"></div>
            <div>
                <img className="login-logo" alt="logo" src="./assets/images/logo-layout.png" />
            </div>
            <div className="layout-content radiusContent">
                {props.children}
            </div>
            
        </Col>
    </Row>
);
