import * as React from 'react';
import { Image, Tooltip, Button } from 'antd';
import UserContext from "../context/UsrContext";
import { DeleteOutlined } from '@ant-design/icons';

export interface PhotoProps {
    src: string,
    onDelete : any
}
 
export interface PhotoState {
    mouseOver : boolean,
    open : boolean,
}

class Photo extends React.Component<PhotoProps, PhotoState> {
    constructor(props : any){
        super(props);
        
        this.state = {
            mouseOver:false,
            open: false
        };

        this._mouseEnter = this._mouseEnter.bind(this);
        this._mouseLeave = this._mouseLeave.bind(this);
    }

    private _mouseEnter(e : any){
        e.preventDefault();

        if(this.state.mouseOver) return;

        this.setState({  mouseOver: true });
    }

    private _mouseLeave(e : any){
        e.preventDefault();

        if(this.state.mouseOver)
            this.setState({ mouseOver: false });
    }

    render() {
        return (<UserContext.Consumer>
            {(usrCxt : any) => <div className="photo">
                {usrCxt.user && <Tooltip placement="top" title="Delete">
                    <Button onClick={this.props.onDelete} className="btnDeleteImg" type="dashed" shape="circle" icon={<DeleteOutlined />} />
                </Tooltip>}
                <Image
                    onMouseEnter={this._mouseEnter}
                    onMouseLeave={this._mouseLeave}
                    src={this.props.src} />
            </div>}
        </UserContext.Consumer>);
    }
}
 
export default Photo;