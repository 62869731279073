import React from 'react';
import { Route } from 'react-router';

const LayoutedRoute = ({ layout : Layout, component: Component, render, ...rest  } : any) => ( 
    <Route {...rest} 
        render={props => 
            (<Layout pathname={props.location.pathname}>{Component ? <Component /> : render(props)} </Layout>)
        }
    />
);

export default LayoutedRoute;